<!-- Settings -->
<template>
    <div class="card card-custom" v-loading="loading">
        <div class="card-body p-0">
            <!--begin: Settings-->
            <div class="wizard wizard-1" id="kt_wizard_v1" data-wizard-state="step-first" data-wizard-clickable="true">
                <!--begin: Settings Body-->
                <div class="row justify-content-center my-10 px-8 px-lg-10">
                    <div class="col-xl-12 col-xxl-10 invoicepage">
                        <!--begin: Wizard Form-->
                        <form class="form" id="kt_form printSection">
                            <!--begin: Wizard Step 1-->
                            <div class="pb-5" data-wizard-type="step-content" data-wizard-state="current">                                 
                                  <el-row>
                                        <el-col :span="12" align="left" v-if="view.website_details">
                                          <span v-loadimage="view.website_details.website_logo" ></span>                                           
                                        </el-col>
                                        <el-col :span="12" align="right">
                                             <b >
                                        <label class="mt-10">{{view.website_details.address}}, {{view.website_details.city_details.city_name}},{{view.website_details.state_details.state_name}} - {{view.website_details.post_code}}, {{view.website_details.country}}.</label><br>                                      
                                        <label>Phone: {{view.website_details.phone}}</label><br>
                                        <label>Fax: {{view.website_details.fax}}</label><br>
                                        <label>Email: {{view.website_details.email_address}}</label>
                                        </b>
                                        <br>
                                        </el-col>
                                  </el-row>
                                  <hr class="invoice-line">
                                  <el-row>                                    
                                      <el-col :span="24" align="center">
                                      <h2 style="font-family: Arial;text-align: center;"><b>CANCEL ORDER</b></h2>
                                      </el-col>                                                                            
                                  </el-row>
                                  <el-row style="margin-top:5px; margin-bottom:5px;">
                                       <el-col :span="12"  align="left">
                                           <span><b>ORDER : </b></span><span><b> {{view.order_number}}</b></span>
                                       </el-col>
                                        <el-col :span="12"  align="right">
                                           <span><b>CUSTOMER ID : </b></span><span><b>{{view.customer.customer_id}}</b></span>
                                       </el-col>
                                       <el-col :span="12"  align="left">
                                           <span><b>BACKORDER ID : </b></span><span><b> {{bakorder.order_number}}</b></span>
                                       </el-col>
                                  </el-row> 
                                   <el-row class="add-info">
                                      <el-col :span="8" >
                                          <div class="billing-adr" style="padding-left:10px;">BILL TO : </div>
                                          <div style="padding: 3px" v-if="view.customer.company_name">{{view.customer.company_name}}</div>
                                          <div style="padding:3px" v-if="view.customer"><span v-if="view.customer.first_name">{{view.customer.first_name}}</span><span v-if="view.customer.last_name">{{view.customer.last_name}}</span></div>
                                          <div style="padding: 3px;" ><span v-if="view.billing_address_order.address_line_2">{{view.billing_address_order.address_line_2}}</span><span v-if="view.billing_address_order.address_line_1">, Unit No : {{view.billing_address_order.address_line_1 }}</span></div>
                                          <div style="padding: 3px;" v-if="view.billing_address_order.city_details">{{view.billing_address_order.city_details.city_name}}</div>
                                          <div style="padding: 3px;" v-if="view.billing_address_order.state_details">{{view.billing_address_order.state_details.state_name}}<span v-if="view.billing_address_order.post_code"> - {{view.billing_address_order.post_code}}</span></div>
                                      </el-col>
                                      <el-col :span="8" align="left" :offset="8" v-if="view.shipping_address_order">
                                         <div class="billing-adr" style="padding-left:10px;">SHIP TO :</div>
                                         <div style="padding: 3px" v-if="view.customer.company_name">{{view.customer.company_name}}</div>
                                         <div style="padding:3px" v-if="view.customer">{{view.customer?view.customer.first_name+' '+(view.customer.last_name==null?'':view.customer.last_name):''}}</div>
                                          <div style="padding: 3px;" ><span v-if="view.shipping_address_order.address_line_2">{{view.shipping_address_order.address_line_2}}</span><span v-if="view.shipping_address_order.address_line_1">, Unit No : {{view.shipping_address_order.address_line_1 }}</span></div>
                                          <div style="padding: 3px;" v-if="view.shipping_address_order.city_details">{{view.shipping_address_order.city_details.city_name}}</div>
                                          <div style="padding: 3px;" v-if="view.shipping_address_order.state_details">{{view.shipping_address_order.state_details.state_name}}<span v-if="view.shipping_address_order.post_code"> - {{view.shipping_address_order.post_code}}</span></div>
                                      </el-col>
                                    </el-row>                                  
                                      <div class="row">
                                      <div class="col-xl-12 ">  
                                  <el-table :data="form.products" stripe border fit >                                                         
                                  <el-table-column
                                    type="index"
                                    width="50">                                    
                                  </el-table-column>
                                  <el-table-column label="Item #" width="100">
                                       <template slot-scope="scope">
                                         <span>{{form.products[scope.$index].product_item.sku}}</span>                                                                                  
                                      </template>
                                  </el-table-column>
                                  <el-table-column label="Product Name" >                                     
                                       <template slot-scope="scope">
                                         <span>{{form.products[scope.$index].product_item.name}}</span>                                         
                                      </template>
                                  </el-table-column>                                  
                                  <el-table-column label="Quantity" width="100" align="center">
                                      <template slot-scope="scope">
                                        <span>{{form.products[scope.$index].back_quantity}}</span>                                         
                                      </template>
                                  </el-table-column>
                                   <el-table-column label="Unit Price" width="100" align="center">
                                      <template slot-scope="scope">
                                        <span>${{form.products[scope.$index].unit_price}}</span>                                         
                                      </template>
                                  </el-table-column>                                  
                                  <el-table-column label="Total" width="150" align="center">
                                     <template slot-scope="scope">
                                         <span>${{form.products[scope.$index].sub_total = parseFloat(form.products[scope.$index].unit_price*form.products[scope.$index].back_quantity).toFixed(2)}}</span>                                               
                                      </template>                            
                                  </el-table-column>                       
                                </el-table> 
                                <el-form >                               
                                <el-row class="pricing-section mt-5 ">
                                  <el-col :span="16">
                                    <el-col :span="20">                                                            
                                      <el-form-item label="Cancel Reason" prop="comments">
                                        <el-input type="textarea" @input.native="capitalize" :rows="4" v-model="form.cancel_reason"></el-input>
                                      </el-form-item>  
                                      <span class="form-err" v-if="form.errors.has('cancel_reason')" >{{ form.errors.get("cancel_reason") }}</span>                                                        
                                    </el-col>
                                  </el-col>
                                  <el-col :span="8">
                                      <el-form :label-position="labelPosition" label-width="150px" >
                                        <el-form-item label="Sub Total" prop="discount">
                                            <el-input v-model="form.sub_total" auto-complete="off" :min="0" class="return_product" disabled></el-input>
                                        </el-form-item>
                                        <el-form-item label="Tax(13 %)" prop="tax">
                                            <el-input v-model="form.tax_total" auto-complete="off" :min="0" class="return_product" disabled></el-input>
                                        </el-form-item>
                                        <el-form-item label="Adjustment Amount" prop="shipping">
                                            <el-input v-model="form.adjustment_amount" auto-complete="off" :min="0" class="return_product"  @change="calculateTotal()"></el-input>
                                        </el-form-item>
                                        <el-form-item label="Total" prop="total">
                                            <el-input v-model="form.cancel_amount" auto-complete="off" disabled class="sub-total return_product" v-if="form.paid_amount>0"></el-input>
                                            <el-input v-model="form.cancel_amount" auto-complete="off" disabled class="sub-total return_product" v-else></el-input>
                                        </el-form-item>
                                        </el-form>
                                    </el-col>
                                </el-row>
                                <el-row class="mb-3 text-right" v-for="(pay,index) in payments" :key="index">
                                    <el-col >                                                                                 
                                      <span class="blc-label" v-if="pay.payment_method == 'Credit Card'">{{pay.brand}} **** **** **** {{pay.last_4}}</span>  
                                        <span class="blc-label" v-else>{{pay.payment_method}} Payment</span> 
                                      <span class="ml-2"> {{pay.payment_date | formatDate}}</span><span style="margin-left:5px;">Paid :</span><span > <b>${{pay.due_amount}}</b></span>
                                    </el-col>
                                  </el-row> 
                                  <el-row style="margin-top:25px;" class="refund_method">                                     
                                      <el-col :span="8" >                                             
                                        <el-form-item label=" Select Refund Method" prop="verified_by" >                                       
                                            
                                            <el-radio  v-model="form.return_amount_type" label="refund_online" v-if="ROnline_status == true">Refund to Online</el-radio>
                                            <el-radio  v-model="form.return_amount_type" label="refund_offline" v-if="ROffline_status == true">Refund to Offline</el-radio>
                                        </el-form-item>  
                                        <span class="form-err" v-if="form.errors.has('return_amount_type')" >{{form.errors.get('return_amount_type')}}</span> 
                                      </el-col>                                    
                                         </el-row> 
                                         <el-row class="mt-5">
                                            <el-col >
                                            <el-form-item >
                                                  <el-button type="warning" @click="createCancelOrder">{{submit_btn_text}}</el-button>
                                            </el-form-item>
                                            </el-col>
                                         </el-row> 
                                         </el-form>                          
                                       </div>
                                      </div>                                
                                       <!-- <el-row>                                      
                                      <el-col :span="24" align="left">
                                      <div style="text-align: center;"><b>If you have any questions about this order, please contact</b></div>
                                      <div style="text-align: center; font-size:15px;"><b>EMAIL: INFO@CTSBUILDINGSUPPLIES.COM</b></div>
                                      </el-col>
                                       </el-row>                                      -->
                              
                               </div>
                            
                        </form>
                        <!--end: Settings Form-->
                    </div>
                </div>
                <!--end: Settings Body-->
            </div>
        </div>
        <!--end: Settings-->
    </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import moment from 'moment';
import {getbBackorder} from "@/api/orderStatus"
import {getPaymentDetails,getPayments} from "@/api/invoice";
import Form from '@/core/services/api.form.services'
export default {
  name: 'view-order',
  components: {},
   data() {
    return {
      loading: false,
      view:[],
      viewitem:[],      
      labelPosition: "right",      
       api_url:'cancel/back/order',
       ROnline_status : true,
       ROffline_status : true,
       SC_status : true,
       CM_status:true,
       form : new Form({
        order_id:null,
        customer_id:null,
        cancel_reason:null,
        sub_total:0,
        tax_total:0,
        adjustment_amount:'$0.00',
        cancel_amount:0,
        return_amount_type:null,
        backorder_id : null, 
        paid_amount:null,
        payment_type:null,       
          products: [
           {
             id :null,
             product_id: null,                       
             quantity: null, 
             back_quantity:null,
             unit_price:null,
             sub_total:null,
             product_item: [{
               sku: null,
               name:null
             }]            
           }
         ]

       }),
       payments:[],   
       submit_btn_text:'Cancel Order',  
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Back orders", route: "/order/list" },
      { title: "Cancel Order" }
    ]);
  },
  created() {
    this.fetchData(1)   
  },
  methods: {
    fetchData() {
      this.loading = true
      getbBackorder(this.$route.params.backorder_id).then(response => {
        this.view = response.data.data 
        this.form.products = response.data.data.order_items; 
        this.bakorder = response.data.backorder; 
        this.form.order_id = response.data.data.id; 
        this.form.customer_id = response.data.data.customer.id
        this.getPayments(response.data.data.invoice.id);
        this.calculateTotal();
        getPayments(response.data.data.invoice.id).then(response => {
          this.payments = response.data.data
          this.loading = false;
        })    
        this.loading = false
      });
     
      
    
    },
    getPayments(invoice_id){
        getPaymentDetails(invoice_id).then(response => {
          let payment = response.data.data;               
          let paymentCount = Object.keys(payment).length;
          // let paymentMethod  = Object.keys(payment);          
          if(paymentCount == 1){
            if(Object.keys(payment) == 'Term'){ 
              this.ROnline_status = false;
              this.ROffline_status = true;
              this.form.return_amount_type= 'refund_offline';
            }else if(Object.keys(payment) == 'Credit Card'){ 
               if(Object.values(payment) > this.form.cancel_amount){                
                 this.ROnline_status = true;
                 this.ROffline_status = true;
               }
              
            }else{             
              this.ROnline_status = false;
              this.ROffline_status = true;
              this.form.return_amount_type= 'refund_offline';   
            }
          }else if(paymentCount > 1){
              this.ROnline_status = false;
              this.ROffline_status = true;    
              this.form.return_amount_type= 'refund_offline';    
          }          
        });
    },
    calculateTotal() {				
        let sub_totals = 0;        
        let adjustment = this.form.adjustment_amount.replace(/\$|,/g, '');                 
        this.form.products.map((product) => {
            sub_totals = sub_totals + (product.back_quantity*product.unit_price);           
        })
        let tax = sub_totals+(sub_totals*13 / 100);
        this.form.sub_total = '$' +parseFloat(sub_totals).toFixed(2);
        this.form.tax_total = '$' + parseFloat(tax).toFixed(2);
        this.form.adjustment_amount = '$' +parseFloat(adjustment).toFixed(2);
        this.form.cancel_amount = '$' +parseFloat(sub_totals-adjustment).toFixed(2);
    },
    createCancelOrder: function() {                
     this.form.backorder_id = this.$route.params.backorder_id;
      this.form.post(this.api_url).then((response) => {
        if(response.status){
            this.$router.push({ path: '/back/orders' });
            this.loading=false;
            this.$showResponse('success', response.message);
        }
      }).catch((error) => {
				  this.loading = false;
	        	console.log(error)
	      	})
    }, 
    printWindow: function () {		
	     window.print();
    },
    format_date(value){
        if (value) {
        return moment(String(value)).format('DD-MM-YYYY')
        }
    },
   
  }
};
</script>

<style >
    hr.invoice-line{
        border-top: 1px solid black;
        width: 100%;
    }
    .billing-adr, .shipping-adr{
         font-size: 15px; 
         background-color:#366092; 
         color: #ffffff;
         padding:3px;
        border:1px solid #366092;
    }
    .invoice-header{
        background-color:#366092; 
        color: #ffffff;
    }
    #website-table th, #website-table td {
      border: 1px solid #366092; padding: 5px;
    }
    .tab-row-hover {
        border: 1px solid #366092;
    }
    .add-info{
      margin-bottom: 20px;
    }
    .default-logo img{
      width: 125px;margin-top:30px;margin-left: 10px;
    }
     .return_product .el-input__inner {
            /* padding-left: 0px;
            padding-right: 8px; */
            border: 1px solid #3699ff;
            text-align: end !important;
        }
        .refund_method{
           background-color: aliceblue;
           padding: 10px 10px 10px 30px;
        }
    @media print {
       
        .print-section, .download-btn, .subheader, .action_btn, .action_dropdown {
            visibility: hidden;
            -webkit-print-color-adjust: exact;
        }
         .billing-adr, .shipping-adr{
         font-size: 15px; 
         background-color:#366092; 
         color: #ffffff;
         -webkit-print-color-adjust: exact;
        
    }

    .table th { 
        background-color: #366092 !important; 
         color: #ffffff !important;
         -webkit-print-color-adjust: exact;
    } 

    
    

}
 
</style>